<template>
  <div class="succeed">
    <img src="~assets/images/icon/succeed.png" alt="" />
    <div class="hint">Congratulations, Submitted Successfully</div>
    <div class="path">
      <span @click="path">View Order</span>
    </div>
  </div>
</template>
<script>
export default {
  methods: {
    path() {
      this.$router.push("/member");
    },
  },
};
</script>
<style scoped>
p {
  margin: 0;
}
.succeed {
  border: 1px solid #ededed;
  box-shadow: 0.1rem 0.1rem 0.1rem #ededed;
  margin-top: 0.5rem;
  text-align: center;
}
img {
  width: 2.5rem;
  height: 2.5rem;
  margin-top: 2.5rem;
}
.hint {
  color: #333333;
  font-size: 0.5rem;
  font-weight: 600;
  margin: 0.5rem 0;
}
.path {
  padding: 1rem 0;
}
.path span {
  color: #ff7f00;
  font-size: 0.35rem;
  text-decoration: underline;
  cursor: pointer;
}
</style>
